import ContactForm from "../../components/forms/contactForm";
import "./loginContactRegistration.css";

function ContactUs() {
  return (
    <div className="contactFormHolder">
      <ContactForm />
    </div>
  );
}

export default ContactUs;
