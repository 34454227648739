import React, { useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { BrowserView, MobileView } from 'react-device-detect';

import "./main.css";


//components

function Main() {
  const navigate = useNavigate();
  const myRef = useRef(null);
  const [hasPaid, setHasPaid] = useState(false);
  const [planName, setPlanName] = useState("");

  const {userInfo, loading, success, successMessage} = useSelector(
    (state) => state.auth
  );

useEffect(() => {
  if(!loading && success && successMessage === "Check User Success"){
    const paid = userInfo.hasPaid;
    setHasPaid(paid);
  }
})
  

  const scrollToMe = () => myRef.current.scrollIntoView();

  return (
    <>
    <BrowserView>
      <div>
          <section className="mainIntro">
            <div className="mainStuff">
              <h2> Welcome to Bungalow52 IRPA Page</h2>
              <p>Lets get started and login</p>
              <Link to="/login">Login Now</Link>
            </div>
          </section>
      </div>
    </BrowserView>
    <MobileView>
    <section className="mobileIntro">
            <div className="mainStuff">
              <h2> Welcome to Bungalow52 IRPA Page</h2>
              <p>Lets get started and login</p>
              <Link to="/login">Login Now</Link>
            </div>
        </section>
    </MobileView>
    </>
  );
}
export default Main;
