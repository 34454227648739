import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit';
import baseURL from '../../helpers/constants/baseUrl';

export const registerUser = createAsyncThunk(
    'auth/register',
    async ({email, password, confirmPassword}, {fulfillWithValue,rejectWithValue}) => {
        try{
            const config = {
                headers:{
                    'Content-Type': 'application/json',
                },
            }
            const response = await axios.post(`${baseURL}user/signup`,{email, password, confirmPassword},config);
                //we are going to login them in directly from here now if success
                if(response.status === 200 || response.status === 301){
                    alert("Thank you for registering with Bungalow 52. Lets create your adventure. Coming soon of course!!!")
                    const user = await login(email, password);
                    return fulfillWithValue(user)
                }
        }catch(error){
            if(error.response && error.response.data.message){
                return rejectWithValue(error.response.data.message)
            }else{
                return rejectWithValue(error.message)
            }
        }
    }
)

const login = async (email, password) => {
    const config = {
        headers:{
            'Content-Type': 'application/json',
        },
    }

    const response = await axios.post(`${baseURL}user/login`, {email, password}, config)
    if (response.status === 200 || response.status === 304){
        try {
            const userInfo = {
                token:response.data.token,
                email: response.data.email,
                member: response.data.member,
                id: response.data.id
            }
            await createKeys(userInfo);
            const user = localStorage.getItem('user');
            return user;
            
        } catch (error) {
            alert("There Was an error logging in", error);                                       
        }
    } 
}

export const loginUser = createAsyncThunk(
    'auth/login',
    async ({email, password}, {fulfillWithValue, rejectWithValue}) => {
        try {
            const config = {
                headers:{
                    'Content-Type': 'application/json',
                },
            }
            const response = await axios.post(`${baseURL}user/login`, {email, password}, config);
                            if (response.status === 200 || response.status === 304){
                                try {
                                    let userInfo = {};
                                        userInfo = {
                                            token: response.data.token,
                                            email: response.data.email,
                                            hasSubriptions: response.data.hasSubcriptions,
                                            member: response.data.member,
                                            id: response.data.id
                                        }

                                    await createKeys(userInfo);
                                    const user = localStorage.getItem('user');
                                    return fulfillWithValue(user);
                                    
                                } catch (error) {
                                    if(error.response && error.response.data.message){
                                        return rejectWithValue(error.response.data.message)
                                    }else{
                                        return rejectWithValue(error.message)
                                    }                                        
                                }
                            }
        } catch (error) {
            if(error.response && error.response.data.message){
                return rejectWithValue(error.response.data.message)
            }else{
                return rejectWithValue(error.message)
            }            
        }
    }
)

const createKeys = async(userInfo) => {
    localStorage.setItem("user", JSON.stringify(userInfo));
}

export const checkUser = createAsyncThunk(
    'auth/check',
    async ({}, {fulfillWithValue, rejectWithValue}) => {
        try {
            const userData = localStorage.getItem("user")
            if(!userData){
                return rejectWithValue(userData)
            }else{
                const user = JSON.parse(userData);
                const response = await axios.get(`${baseURL}user/check`,{headers:{'Authorization': `Bearer ${user.token}`}});
                if (response.status === 200 || response.status === 304){
                    return fulfillWithValue(response.data.user)
                }  
            }
        }catch(error){
            if(error.response && error.response.data.message){
                return rejectWithValue(error.response.data.message)
            }else{
                return rejectWithValue(error.message)
            }               
        }
    }
);

export const logout = createAsyncThunk(
    'auth/logout',
    async({token,userId},{fulfillWithValue, rejectWithValue}) => {
        try {
            const config = {
                headers:{
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            }
            const response = await axios.post(`${baseURL}user/${userId}/logout`, {}, config);
            if(response.status === 200 || response.status === 304){
                const userInfo = localStorage.removeItem('user');
                alert("You Have Successfully Logged Out!");
                return fulfillWithValue(userInfo);
            }else{
                return rejectWithValue(response.data);
            }

        } catch (error) {
            if(error.response && error.response.data.message){
                return rejectWithValue(error.response.data.message)
            }else{
                return rejectWithValue(error.message)
            }                 
        }
    }
);
