import { createSlice } from "@reduxjs/toolkit";
import { loginUser, registerUser, checkUser, logout } from "../feature/authActions";

const initialState = {
    loading: false,
    userInfo: {},
    error: null,
    errorMessage: "",
    success: false,
    successMessage: "",
}

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      
      //For Logging User In
      builder.addCase(loginUser.pending, (state) => {
        state.loading = true;
        state.error = null
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.loading = false
        state.success = true
        state.successMessage = "Login Success"
        state.errorMessage = ""
        state.error = null
        state.userInfo = action.payload
      })
      .addCase(loginUser.rejected, (state, {payload}) => {
        state.loading = false
        state.success = false
        state.error = payload
        state.errorMessage = "Login Error"
      })
        //For Registering User
        .addCase(registerUser.pending, (state) => {
          state.loading = true;
          state.error = null
        })
        .addCase(registerUser.fulfilled, (state) => {
          state.loading = false
          state.success = true
          state.successMessage = "Registered Success"
          state.error = null
          state.errorMessage = "";
        })
        .addCase(registerUser.rejected, (state, {payload}) => {
          state.loading = false
          state.success = false
          state.error = payload
          state.errorMessage = "Registration Error"
        })

          //For Checking User
        .addCase(checkUser.pending, (state) => {
          state.loading = true;
          state.error = null
        })
        .addCase(checkUser.fulfilled, (state, action) => {
          state.userInfo = action.payload
          state.loading = false
          state.success = true
          state.successMessage = "Check User Success"
          state.errorMessage = ""
          state.error = null
          
        })
        .addCase(checkUser.rejected, (state, {payload}) => {
          state.loading = false
          state.success = false
          state.userInfo = {}
          state.errorMessage = "Check User Error"
          state.error = payload
        })

          //For Logging out user
          .addCase(logout.pending, (state) => {
            state.loading = true;
            state.error = null
          })
          .addCase(logout.fulfilled, (state) => {
            state.loading = false
            state.success = true
            state.successMessage = "Logout Success"
            state.errorMessage = ""
            state.userInfo = {}
            state.error = null
          })
          .addCase(logout.rejected, (state, payload) => {
            state.loading = false
            state.success = false
            state.errorMessage = "Logout Error"
            state.error = payload
          })        
      },
})

export default authSlice.reducer;