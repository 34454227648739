function MyAccount () {
    return(
        <div>
            <section>
                <h2>Welcome to My Account Page</h2>
            </section>
        </div>
    );
}

export default MyAccount;