import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { Link, useNavigate} from "react-router-dom";
import { Form, Formik} from "formik";

import { basicSchema } from "../../../helpers/schemas/index";
import "../../../components/forms/loginRegisterForm.css";
import { FormInput } from "../../../components/forms/inputs/formInputs";
import DescriptionInput from "../../../components/forms/inputs/descriptionInput";

import Button from "../../buttons/button";
import baseURL from "../../../helpers/constants/baseUrl";

const BookForm = ({selectedGenre, createdBook}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [title, setTitle] = useState("");
  const [author, setAuthor] = useState("");

  const [summary, setSummary] = useState("");

  const {userInfo, loading, error, success} = useSelector(
    (state) => state.auth
  )

  const submitBook= async() => {
    const values = {
      title,
      author,
      summary,
      genreId: selectedGenre.genreId
    }
    const url = `${baseURL}book/${userInfo.id}/createBook`;
    console.log(values)
    const config = {
      headers:{
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${userInfo.token}`
      },
    };
    const genreId = selectedGenre.genreId; 
    const response = await axios.post(url,{title,author,summary,genreId},config);
    if(response.status === 200){
      alert("You have successfull created your book lets choose a book cover and begin writing your story");
      createdBook(true);
    }
  }

  return (
    <div className="loginForm">
      <h1>B52 Irpa Book Form</h1>
      <Formik
        initialValues={{ title: "", authorName: "", description: "" }}
        validationSchema={basicSchema}
        onSubmit={submitBook}
      >
        <div className="formContents">
          <div>
            <Form>
              <FormInput
                inputHolder="loginHolder"
                typeOfInput="login-register"
                errorType="errorInput"
                onInput={(e) => setTitle(e.target.value)}
                label="Title:"
                name="title"
                type="text"
                placeholder="Enter A Good Title for book..."
              />
              <FormInput
                inputHolder="loginHolder"
                onInput={(e) => setAuthor(e.target.value)}
                typeOfInput="login-register"
                errorType="errorInput"
                label="Author:"
                name="Author"
                type="text"
                placeholder="Enter your Author Name"
              />
              <DescriptionInput
                onInput={(e) => setSummary(e.target.value)}
                inputHolder="contactHolder"
                typeOfInput="contact-describe"
                errorType="errorInput"
                label="Brief Summary of Book"
                name="summary"
                placeholder="Brief description on whats going on"
              />
            </Form>
            <div className="loginNavigation">
              <div className="googleLineSplit"></div>
              <Button
                buttonType="loginButton"
                title="Submit Book"
                swag="login-style"
                internals="submit"
                onPress={() => submitBook()}
              />
            </div>
          </div>
        </div>
      </Formik>
    </div>
  );
};

export default BookForm;
