import LoginForm from "../../components/forms/loginForm";
import "./loginContactRegistration.css";

function Login() {
  return (
    <div className="loginFormHolder">
      <LoginForm />
    </div>
  );
}

export default Login;
