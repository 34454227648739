import { useState, useEffect } from "react";
import ChooseGenre from "../components/storyComponents/forms/chooseGenre";
import BookForm from "../components/storyComponents/forms/bookForm";

function StoryCreator() {
  const [hasSelected, setHasSelected] = useState(false);
  const [hasCreatedBook, setHasCreatedBook] = useState(false);
  const [items, setItems] = useState({});

  useEffect(() => {
    console.log(items);
  }, [hasSelected, items, hasCreatedBook]);

  return (
    <div>
      <h2>Lets create a story</h2>
      {!hasSelected ? (
        <ChooseGenre myGenre={setItems} hasChoosen={setHasSelected} /> 
      ): !hasCreatedBook ? (
          <BookForm selectedGenre={items} createdBook={setHasCreatedBook}/>
      ):(
        <div>
          <h2>Choose Your Book Cover</h2>
          <h3>Non to display at this time</h3>
        </div>
      )}
    </div>
  );
}

export default StoryCreator;
