import "./forminputs.css";
import { useField } from "formik";

export const FormInput = ({
  inputHolder,
  typeOfInput,
  errorType,
  label,
  ...props
}) => {
  const [field, meta] = useField(props);

  return (
    <div className={inputHolder}>
      <label>{label}</label>
      <input
        {...field}
        {...props}
        className={meta.touched && meta.error ? errorType : typeOfInput}
      />
      {meta.touched && meta.error && (
        <div className="messageError">
          <p>{meta.error}</p>
        </div>
      )}
    </div>
  );
};
