import { Link } from "react-router-dom";
import { BrowserView, isTablet, MobileView } from 'react-device-detect';
import {
  ImFacebook2,
  ImInstagram,
  ImTwitter,
  ImLinkedin2,
} from "react-icons/im";

import "./footer.css";
const Footer = () => {
  return (
    <>
    <BrowserView>
    <div className="footerContainer">
      <div className="footerContents">
        <div className="footerAbout">
          <h2>About Bungalow 52 IRPA</h2>
          <p>
            We are a subsidiary company under Bungalow52. We want people to enjoy story telling and reading again. We designed this site under the premise of creating a fun way to create stories and interact with other creative story tellers in an interactive way.
          </p>
        </div>
        <div className="linkHolders">
          <div>
            <ul>
              <h2>Company Links</h2>
              <li className="company">
                <Link to="/contact">Contact Us</Link>
              </li>
              <li className="company">
                <Link>Privacy Policy</Link>
              </li>
              <li className="company">
                <Link>Terms & Conditions</Link>
              </li>
              <li className="company">
                <Link to="http://bungalow52.com">Other Services</Link>
              </li>
            </ul>
          </div>
          <div>
          </div>
        </div>
      </div>
    </div>
    </BrowserView>
    <MobileView>
    <div className="mobileContainer">
      <div className="mobileContents">
        <div className="mobileAbout">
        <h2>About Bungalow 52 IRPA</h2>
          <p>
            We are a subsidiary company under Bungalow52. We want people to enjoy story telling and reading again. We designed this site under the premise of creating a fun way to create stories and interact with other creative story tellers in an interactive way.
          </p>
        </div>
        <div className="mobileHolders">
          <div>
            <ul>
              <h2>Company Links</h2>
              <li className="mcompany">
                <Link to="/contact">Contact</Link>
              </li>
              <li className="mcompany">
                <Link>Privacy </Link>
              </li>
              <li className="mcompany">
                <Link>Terms</Link>
              </li>
              <li className="mcompany">
                <Link to="http://bungalow52.com">Services</Link>
              </li>
            </ul>
          </div>
          <div>
          </div>
        </div>
      </div>
    </div>
    </MobileView>
    </>
  );
};

export default Footer;
