import Helmet from "react-helmet";
import { Routes, Route } from "react-router";
import { useDispatch } from "react-redux";
import "./App.css";

//pages
import Main from "./pages/Main";
import Login from "./pages/users/Login";
import Register from "./pages/users/Register";
import ContactUs from "./pages/users/ContactUs";
import MyAccount from "./pages/users/myAccount";
import Success from "./pages/gateways/success";
import Cancel from "./pages/gateways/cancel";

import { checkUser } from "../src/redux/feature/authActions";
import AdminPortal from "./pages/users/adminPortal";
import StoryCreator from "./pages/StoryCreator";
import Books from "./pages/Books";

function App() {
  const dispatch = useDispatch();
  
  dispatch(checkUser(""));
  return (
    <>
      <Helmet>
        <title>Interactive Role Playing Adventure</title>
        <meta
          name="description"
          content="A highly interactive system that lets you build awesome adventure story games"
        />
        <meta
          name="keywords"
          content="irpa, writing, creativity, creative-writing"
        />
      </Helmet>
      <Routes>
        <Route path="/" element={<Main />} exact />
        <Route path="/login" element={<Login />} exact />
        <Route path="/signup" element={<Register />} exact />
        <Route path="/contact" element={<ContactUs />} exact />
        <Route path="/myAccount" element={<MyAccount />} exact />
        <Route path="/adminPortal" element={<AdminPortal />} exact />
        <Route path="/storyCreator" element={<StoryCreator />} exact />
        <Route path="/success" element={<Success />} exact />
        <Route path="/cancel" element={<Cancel />} exact />
        <Route path="/Books" element={<Books/>} exact />
      </Routes>
    </>
  );
}

export default App;
