import React, { useEffect, useState } from "react";
import {BrowserView, MobileView, isDesktop} from "react-device-detect";
import { Link, useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import { basicSchema } from "../../helpers/schemas/index";

import "./loginRegisterForm.css";
import { FormInput } from "./inputs/formInputs";

import Button from "../buttons/button";
import { GoogleLogin } from "@react-oauth/google";
import { useDispatch, useSelector} from "react-redux";
import { registerUser } from "../../redux/feature/authActions";

export const RegisterForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //user values
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");


  const {loading, userInfo, error, success} = useSelector(
    (state) => state.auth
  )


  useEffect(() => {
    if(!loading && !error && success == true){
      navigate('/packages')
    }
  }, [userInfo])

  const handleMe = async () => {
    const values = {
      email,
      password,
      confirmPassword,
    };
    if (
      values.email === "" ||
      values.password === "" ||
      values.confirmPassword === ""
    ) {
      alert("You Must fill Out all required fields in order to proceed");
    } else {
      if(isDesktop){
        dispatch(registerUser(values));
      }else{
        console.log("this is being called to mobile device")
      }
      
    }
  };

  return (
    <>
      <BrowserView className="loginForm">
      <h1>B52 IRPA Registration</h1>
      <Formik
        initialValues={{
          email: "",
          password: "",
          confirmPassword: "",
        }}
        validationSchema={basicSchema}
        onSubmit={handleMe}
      >
        <div className="formContents">
          <div>
            <Form>
              <FormInput
                value={email}
                onInput={(e) => setEmail(e.target.value)}
                inputHolder="loginHolder"
                typeOfInput="login-register"
                errorType="errorInput"
                label="Email:"
                name="email"
                type="text"
                placeholder="Enter Your Email..."
              />
              <FormInput
                value={password}
                onInput={(e) => setPassword(e.target.value)}
                inputHolder="loginHolder"
                typeOfInput="login-register"
                errorType="errorInput"
                label="Password:"
                name="password"
                type="password"
              />
              <FormInput
                value={confirmPassword}
                onInput={(e) => setConfirmPassword(e.target.value)}
                inputHolder="loginHolder"
                typeOfInput="login-register"
                errorType="errorInput"
                label="Confirm Password:"
                name="confirmPassword"
                type="password"
              />

              <div className="loginNavigation">
                <div className="googleLineSplit"></div>
                <GoogleLogin />
                <h3>
                  Already have an Account <Link to="/login">Login Here</Link>
                </h3>
                <Button
                  buttonType="loginButton"
                  internals="button"
                  title="Register"
                  swag="login-style"
                  onPress={() => handleMe()}
                />
              </div>
            </Form>
          </div>
        </div>
      </Formik>
      </BrowserView>
      <MobileView className="mLoginForm">
      <h1>Ghosty Registration</h1>
      <Formik
        initialValues={{
          email: "",
          password: "",
          confirmPassword: "",
        }}
        validationSchema={basicSchema}
        onSubmit={handleMe}
      >
        <div className="formContents">
          <div>
            <Form>
              <FormInput
                value={email}
                onInput={(e) => setEmail(e.target.value)}
                inputHolder="loginHolder"
                typeOfInput="login-register"
                errorType="errorInput"
                label="Email:"
                name="email"
                type="text"
                placeholder="Enter Your Email..."
              />
              <FormInput
                value={password}
                onInput={(e) => setPassword(e.target.value)}
                inputHolder="loginHolder"
                typeOfInput="login-register"
                errorType="errorInput"
                label="Password:"
                name="password"
                type="password"
              />
              <FormInput
                value={confirmPassword}
                onInput={(e) => setConfirmPassword(e.target.value)}
                inputHolder="loginHolder"
                typeOfInput="login-register"
                errorType="errorInput"
                label="Confirm Password:"
                name="confirmPassword"
                type="password"
              />

              <div className="loginNavigation">
                <div className="googleLineSplit"></div>
                <GoogleLogin />
                <h3>
                  Already have an Account <Link to="/login">Login Here</Link>
                </h3>
                <Button
                  buttonType="loginButton"
                  internals="button"
                  title="Register"
                  swag="login-style"
                  onPress={() => handleMe()}
                />
              </div>
            </Form>
          </div>
        </div>
      </Formik>
      </MobileView>
    </>

  );
};
