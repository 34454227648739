import './popUp.css';
import Select from 'react-select'
import { useState } from 'react';

//Icons
import { IoMdCloseCircle } from "react-icons/io";
import { FaThumbsUp } from "react-icons/fa6";
import { FaThumbsDown } from "react-icons/fa6";

const PopUp = ({title, message, onPress, onClose, onChange, buttonTitle, isPackage, popType, success, cancel}) => {

    const sendText = (event) => {
        onChange(event.value)
    }

    const options = [
        { value: 'monthly', label: 'monthly' },
        { value: 'annually', label: 'annually' }
      ]
    return(
        <div className="popUp">

            <div className={`modal ${popType}`}>
            <div className='closePop'>
                <IoMdCloseCircle size={40} onClick={onClose}/>
            </div>
            {success ? (
                    <FaThumbsUp size={100} color='green'/>
            ): cancel ? (
                <div>
                    <FaThumbsDown size={100} color='red' />
                </div>
            ):null}
                <h2>{title}</h2>
                <p>{message}</p>
                {isPackage &&(
                    <div className='selection'>
                        <Select styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                width: '250px',
                                height: '65px'
                            })
                        }} options={options} onChange={sendText}/>
                    </div>
                )}
                <div>
                    <button onClick={onPress}>{buttonTitle}</button>
                </div>
            </div>
        </div>
    )
}

export default PopUp;