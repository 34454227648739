import axios from "axios";
import baseURL from "../../../helpers/constants/baseUrl";
import { useEffect, useState } from "react";

import "./choices.css";
import { FaBook } from "react-icons/fa";

//We will show all genres at first unless user chooses a type of genre first
const ChooseGenre = ({ hasChoosen, myGenre }) => {
  const [genres, setGenres] = useState([]);
  const [genreTypes, setGenreTypes] = useState([]);

  const [hasSelected, setHasSelected] = useState(false);

  useEffect(() => {
    callForGenres();
  }, []);

  const callForGenres = async () => {
    await axios.get(`${baseURL}genres`).then((res) => {
      if (res.status === 202) {
        setGenreTypes(res.data.genreTypes);
      }
    });
  };

  const selectedItem = (item) => {
    setHasSelected(true);

    //This is solely to change the classname to add a clicked effect to genre
    if (item.name === "Fiction") {
      document.getElementById("Fiction").className = "selectedFiction";
      document.getElementById("Non-Fiction").className = "Non-Fiction";
    } else if (item.name === "Non-Fiction") {
      document.getElementById("Non-Fiction").className = "selectedNonFiction";
      document.getElementById("Fiction").className = "Fiction";
    }

    setGenres(item.genres);
  };

  const yourSelection = (item) => {
    let genreType = "";

    if (item.genreType === "669ea333c6c6c044eddd541c") {
      genreType = "Fiction";
    } else {
      genreType = "Non-Fiction";
    }
    alert(
      `You have choosen to create a based in this category - ${item.genreCategory} and type ${genreType}`
    );
    const myItems = {
      genreType,
      genreId: item._id,
      genre: item.genreCategory
    }
    //after you have choosen items we send an alert saying you have choosen then close this form and open another form with pass back to StoryCreator
    myGenre(myItems)
    hasChoosen(true);
  };

  return (
    <div className="genreFormHolder">
      <h1>Choose Your Genre Type</h1>
      <div className="genreContainer">
        <div className="genreTypeHolder">
          {genreTypes.map((item, index) => (
            <div
              key={index}
              id={`${item.name}`}
              className={`${item.name}`}
              onClick={() => selectedItem(item)}
            >
              <FaBook />
              <h5>{item.name}</h5>
            </div>
          ))}
        </div>
        {hasSelected ? (
          <div>
            <div className="underBar"></div>
            <div className="genreCatContainer">
              <div className="genreCat">
                {genres.map((item, index) => (
                  <div
                    key={index}
                    className="gCat"
                    onClick={() => yourSelection(item)}
                  >
                    <h5>{item.genreCategory}</h5>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
      <div></div>
    </div>
  );
};

export default ChooseGenre;
