import React from "react";
import ReactDOM from "react-dom/client";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { Provider} from 'react-redux';
import store from "./redux/store";

import "./index.css";
import App from "./App";

import { BrowserRouter as Router } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";

//navigation tools
import Navbar from "./components/navbar";
import Footer from "./components/footer";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_ID}>
    <Router>
      <Provider store={store}>
        <Navbar />
        <App />
        <Footer />
      </Provider>
    </Router>
  </GoogleOAuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
