import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { BrowserView, MobileView } from "react-device-detect";
import { Link } from "react-router-dom";
import Headroom from "react-headroom";
import { TiThMenuOutline } from "react-icons/ti";
import { AiOutlineFullscreenExit } from "react-icons/ai";
import { FaHouseUser } from "react-icons/fa";



import "./navbar.css";
import { logout } from "../redux/feature/authActions";

const Navbar = () => {
  let selection = "main";
  const [mobileMenu, setMobileMenu] = useState(false);
  const [menuHover, setMenuHover] = useState(false);

  const dispatch = useDispatch();
  const {userInfo} = useSelector(
    (state) => state.auth
  )

  useEffect(() => {
    
  },[mobileMenu,menuHover])

  const showMenu = () => {
    if (!mobileMenu && !menuHover) {
      setMenuHover(true);
      setMobileMenu(true);
      selection = "animate";
    } else {
      setMenuHover(true);
      setMobileMenu(true);
      selection = "main";
    }
  };

  const callForLogout = () => {

    const token = userInfo.token;
    const userId = userInfo.id;
    const user={
      token,
      userId
    }
    dispatch(logout(user))
  }

  return (
    <Headroom>
    <div className="navbar">
      <BrowserView>
        <div className="navContents">
          <ul className="navListHolder">
            <li className="navLinks">
              <Link to="/">Home</Link>
            </li>
            {userInfo.email == null ?(
              <>
                <li className="navLinks">
                  <Link to="/login">Login</Link>
                </li>
                <li className="navLinks">
                  <Link to="/signup">Sign Up</Link>
                </li>
              </>
            ): (
              <>
                <li className="navLinks">
                  <Link to="/myAccount">
                      <FaHouseUser size={25} />
                      My Account
                  </Link>
                </li>
                <li className="navLinks">
                  <Link to="/adminPortal">My Portal</Link>
                </li>
                <li className="navLinks">
                  <Link onClick={() => callForLogout()}>Logout</Link>
                </li>
              </>
            )}
          </ul>
        </div>
      </BrowserView>
      <MobileView>
        <div className="navMenuHolder">
          {!menuHover ? (
            <button onClick={() => showMenu()}>
              <TiThMenuOutline size={80} color="#398291" />
            </button>
          ) : (
            <button onClick={() => showMenu()}>
              <AiOutlineFullscreenExit size={80} color="#fff" />
            </button>
          )}
          <>
            {mobileMenu && (
              <div className={selection}>
                <ul>
                  <li className="mobileLinks">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="mobileLinks">
                    <Link to="/">Packages</Link>
                  </li>
                  <li className="mobileLinks">
                    <a href="https://acgstu.com">Other Services</a>
                  </li>
                  {userInfo.email == null ?(
              <>
                <li className="navLinks">
                  <Link to="/login">Login</Link>
                </li>
                <li className="navLinks">
                  <Link to="/signup">Sign Up</Link>
                </li>
              </>
            ): (
              <>
                <li className="navLinks">
                  <Link to="/myAccount">
                      <FaHouseUser size={25} />
                      My Account
                  </Link>
                </li>
                <li className="navLinks">
                  <Link onClick={() => callForLogout()}>Logout</Link>
                </li>
              </>
            )}
                </ul>
              </div>
            )}
          </>
        </div>
      </MobileView>
    </div>
    </Headroom>
  );
};

export default Navbar;
