import React, { useState, useEffect } from "react";
import { googleLogout, useGoogleLogin } from "@react-oauth/google";
import { useDispatch, useSelector } from "react-redux";
import { GoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { Link, useNavigate} from "react-router-dom";
import { Form, Formik, replace } from "formik";

import { basicSchema } from "../../helpers/schemas/index";
import "./loginRegisterForm.css";
import { FormInput } from "./inputs/formInputs";

import Button from "../buttons/button";
import { loginUser } from "../../redux/feature/authActions";

const LoginForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [user, setUser] = useState([]);
  const [profile, setProfile] = useState([]);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const {userInfo, loading, error, success} = useSelector(
    (state) => state.auth
  )

  //We will use this in redux folder after we get situated there to use every where
  useEffect(() => {
    /*
    if (user) {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          //We will call submit profile data later to store info
          setProfile(res.data);
        })
        .catch((err) => console.log(err));
    }
    */
  }, [userInfo]);

  //this is for using regular login not googles
  const successHandler = () => {
  };

  const expiredHandler = () => {
  };

  const errorHandler = () => {
    alert("Please Check Your Internet Connection");
  };

  //This is for google login
  const googleLogin = useGoogleLogin({
    onSuccess: (codeResponse) => setUser(codeResponse),
    onError: (error) => console.log("Login Failed:", error),
  });

  const googleLogout = () => {
    googleLogout();
    setProfile(null);
  };

  const logMeIn = async() => {
    const values = {
      email,
      password
    };
    if (values.email == "" || values.password == "") {
      alert("You Must fill Out all required fields in order to proceed");
    } else {
      dispatch(loginUser(values)).then(() => {
        alert('You Have Successfully Logged In, redirecting now...');
        navigate("/adminPortal", {replace: true});
        navigate(0);
      });
    }
  }

  return (
    <div className="loginForm">
      <h1>B52 Irpa Login</h1>
      <Formik
        initialValues={{ username: "", password: "" }}
        validationSchema={basicSchema}
        onSubmit={logMeIn}
      >
        <div className="formContents">
          <div>
            <Form>
              <FormInput
                inputHolder="loginHolder"
                typeOfInput="login-register"
                errorType="errorInput"
                onInput={(e) => setEmail(e.target.value)}
                label="Email:"
                name="email"
                type="text"
                placeholder="Enter Your Email Address..."
              />
              <FormInput
                inputHolder="loginHolder"
                onInput={(e) => setPassword(e.target.value)}
                typeOfInput="login-register"
                errorType="errorInput"
                label="Password:"
                name="password"
                type="password"
              />
            </Form>
            <div className="loginNavigation">
              <div className="googleLineSplit"></div>
              <GoogleLogin />
              <h3>
                Don't Have an Account? <Link to="/signup">Register Here</Link>
              </h3>
              <Button
                buttonType="loginButton"
                title="Login Now"
                swag="login-style"
                internals="submit"
                onPress={() => logMeIn()}
              />
            </div>
          </div>
        </div>
      </Formik>
    </div>
  );
};

export default LoginForm;
