import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Form, Formik } from "formik";
import { basicSchema } from "../../helpers/schemas/index";
import axios from "axios";

import "./loginRegisterForm.css";
import { FormInput } from "./inputs/formInputs";
import DescriptionInput from "./inputs/descriptionInput";

import Button from "../buttons/button";

const onSubmit = async (values, actions) => {
  const url = "https://acgstu.com/api/acg/v1/contact/create-contact";
  if (values.email == "" || values.name == "" || values.description == "") {
    alert("You Must fill Out all required fields in order to proceed");
  } else {
    // We will send via axios the values and send email through node js
    await axios.post(url, values).then((res) => {
      if (res.status === 201 || res.status === 200) {
        alert(
          "Thank You for submitting your contact information, Somebody will be with you shortly"
        );
      } else {
        alert("Something went wrong, please try again later");
      }
    });
  }
  await new Promise((resolve) => setTimeout(resolve, 1000));
  actions.resetForm();
};

const ContactForm = () => {
  const [success, setSuccess] = useState(false);

  const successHandler = () => {
    setSuccess(true);
  };

  const expiredHandler = () => {
    setSuccess(false);
  };

  const errorHandler = () => {
    setSuccess(false);
    alert("Please Check Your Internet Connection");
  };

  return (
    <div className="contactForm">
      <h1>Contact Bungalow52</h1>
      <Formik
        initialValues={{ username: "", password: "" }}
        validationSchema={basicSchema}
        onSubmit={onSubmit}
      >
        <div className="formContents">
          <div className="contactFormContents">
            <Form>
              <FormInput
                inputHolder="contactHolder"
                typeOfInput="contact"
                errorType="errorInput"
                label="Name:"
                name="name"
                type="text"
                placeholder="Enter Your Name..."
              />
              <FormInput
                inputHolder="contactHolder"
                typeOfInput="contact"
                errorType="errorInput"
                label="Email:"
                name="email"
                type="text"
                placeholder="Enter Your Email..."
              />
              <FormInput
                inputHolder="contactHolder"
                typeOfInput="contact"
                errorType="errorInput"
                label="Phone Number:"
                name="phone"
                type="tel"
                placeholder="232-343-3456"
                pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
              />
              <DescriptionInput
                inputHolder="contactHolder"
                typeOfInput="contact-describe"
                errorType="errorInput"
                label="How can we be of Service?:"
                name="description"
                placeholder="Brief description on whats going on"
              />
            </Form>
            <div className="contactButton">
              <Button
                buttonType="contactButton"
                title="Contact Ghosty"
                swag="login-style"
              />
            </div>
          </div>
        </div>
      </Formik>
    </div>
  );
};

export default ContactForm;
