import { Link } from "react-router-dom";

function AdminPortal (){
    return(
        <div>
            <section>
                <h2>Welcome to admin portal</h2>
                <h5>What would you like to look at</h5>
                <ul>
                    <li>
                        <Link to="/storyCreator">Create A Story</Link>
                    </li>
                    <li>
                        <Link to="/profile">Check Revenue</Link>
                    </li>
                    <li>
                        <Link to="/stats">Site Statistics</Link>
                    </li>
                    <li>
                        <Link to="/createCover">Create Book Cover</Link>
                    </li>
                    <li>
                        <Link to="/createGenre">Genre Creator</Link>
                    </li>
                </ul>
            </section>
        </div>
    )
}

export default AdminPortal;