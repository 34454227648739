import "./forminputs.css";
import { useField } from "formik";

const DescriptionInput = ({
  inputHolder,
  typeOfInput,
  errorType,
  label,
  ...props
}) => {
  const [field, meta] = useField(props);
  return (
    <div>
      <label>{label}</label>
      <textarea
        {...field}
        {...props}
        className={meta.touched && meta.error ? errorType : typeOfInput}
      />
    </div>
  );
};

export default DescriptionInput;
