import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PopUp from "../../components/popUp";
import axios from "axios";
import baseUrl from "../../helpers/constants/baseUrl";
import { useNavigate } from "react-router-dom";

export default function Success(){

    const [sessionId, setSessionId] = useState("");

    const navigate = useNavigate();
    const {userInfo} = useSelector(
        (state) => state.auth
      );

    useEffect(() => {
        const sesh = localStorage.getItem('sessionId')
        setSessionId(sesh);
    },[userInfo])

    const paymentSuccess = async() => {
        console.log(sessionId)
        const url = `${baseUrl}pay/${userInfo.id}/successfulPayment`;
        const config = {
            headers:{
                "Authorization": `Bearer ${userInfo.token}`
            }
        }
        const res = await axios.post(url,{sessionId},config);
        if(res.status === 200){
            localStorage.removeItem("sessionId");
            alert("Heading to your account page");
            navigate("/myAccount");
        }
    }

    return(
        <div>
            <PopUp
                success={true}
                title="Payment Successful"
                message="Thank you for signing up with Ghosty, an email will be sent out to you shortly"
                buttonTitle="Continue"
                onPress={() => paymentSuccess()}
                isPackage={false}
                popType="success"
            />
        </div>
    );
}