import { RegisterForm } from "../../components/forms/registerForm";

import "./loginContactRegistration.css";

function Register() {
  return (
    <div className="loginFormHolder">
      <RegisterForm />
    </div>
  );
}

export default Register;
