import PopUp from "../../components/popUp";
export default function Cancel(){

    const chooseDevice = () => {

    }

    return(
        <div>
            <PopUp
                cancel={true} 
                title="Cancelled Transaction"
                message="You have cancelled choosing a package with ghosty"
                buttonTitle="Continue"
                onPress={() => chooseDevice()}
                isPackage={false}
                popType="cancellation"
            />
        </div>
    );
}