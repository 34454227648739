import "./button.css";

const Button = ({ buttonType, internals, title, swag, onPress }) => {
  return (
    <div className={buttonType}>
      <button className={swag} onClick={onPress} type={internals}>
        <p>{title}</p>
      </button>
    </div>
  );
};

export default Button;
